
import { Vue, Component } from 'vue-property-decorator'
import { user_columns } from '@/modules/static'
import { UserStore } from '@/store'
import CustomTable from '@/components/CustomTable.vue'
import { User } from '@/modules/models'

@Component({
	components: {
		CustomTable
	}
})
export default class UserList extends Vue {
	columns = user_columns

	get users(): User[]{
		return Object.values(UserStore.users)
	}

	created(){
		UserStore.loadUsers()
	}
}
